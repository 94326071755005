<template>
	<b-overlay :show="loading" rounded="sm">
		<b-row>
			<b-col cols="12">
				<!-- 1 -->
				<b-card no-body class="mb-4">
					<!-- Search Control -->
					<b-card-body class="p-3 d-flex justify-content-between">
						<b-form-group class="mb-2 w-25">
							<b-input-group>
								<b-form-input id="filter-input" v-model="filter" type="search"
									placeholder="Type to Search"></b-form-input>

								<b-input-group-append>
									<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-card-body>
					<!-- End Section -->

					<!-- Data Table -->
					<b-table style="min-height: 250px;" show-empty responsive small class="mb-0" head-variant="light"
						:items="applications" :filter="filter" :fields="fields" hover>
						<template #cell(id)="data"> #{{ generateCode(data.item.id) }} </template>
						<template #cell(isActive)="data">
							<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
							<b-badge variant="danger" v-else>Inactive</b-badge>
						</template>
						<template #cell(progress)="data">
							<b-progress :value="checkProgress(data.item)" height="12px" style="width: 120px;"
								class="bg-light-secondary"></b-progress>
						</template>

						<template #cell(loanAmount)="data"> AWG {{ formatter.format(data.item.loanAmount) }} </template>

						<template #cell(monthlyPayment)="data"> AWG {{ formatter.format(data.item.monthlyPayment) }}
						</template>

						<template #cell(action)="data">
							<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
								<b-dropdown-item @click="goToView(data.item)">Summary</b-dropdown-item>
								<b-dropdown-item @click="goToEdit(data.item)">See process</b-dropdown-item>
								<b-dropdown-item @click="showOfficerModal(data.item)">Change Loan
									Officer</b-dropdown-item>
							</b-dropdown>
						</template>
					</b-table>
					<!-- End Section -->

					<!-- Pagination -->
					<div class="p-3 d-flex align-items-center">
						<b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
							label-cols-lg="9" label-size="sm" label-class="fw-medium" class="mb-0">
							<b-form-select @change="getData()" id="per-page-select" v-model="perPage"
								:options="pageOptions" size="sm"></b-form-select>
						</b-form-group>
						<div class="ml-auto">
							<b-pagination @input="getData()" v-model="currentPage" :total-rows="totalApplications"
								:per-page="perPage" align="fill" class="my-0"></b-pagination>
						</div>
					</div>
					<!-- End Section -->
				</b-card>
			</b-col>
		</b-row>

		<!-- Modal -->
		<b-modal id="modal-view" centered title="Details" v-model="show" @hide="show = false" hide-footer>
			<b-card-text v-if="applicationSelected">
				<Stepper />
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Customer Name</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.customerName }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Loan Amount</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.loanAmount }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Monthly Payment</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.monthlyPayment }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Period In Months</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.periodInMonths }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Loan Type</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.loanDescription }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Agent Name</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.agentName }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Created By</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.createdByUserName }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Status</h6>
					<span class="mail-desc d-block text-muted">{{ applicationSelected.isActive ? "Active" : "Inactive"
						}}</span>
				</div>
			</b-card-text>
		</b-modal>

		<b-modal id="modal-officer" centered title="Change Loan Officer" hide-footer>
			<div>
				<label for="">Select an officer</label>
				<b-form-select class="mb-3" :options="officers" v-model="selectedOfficer" value-field="id"
					text-field="userName" size="sm"></b-form-select>

				<b-button variant="primary" @click="setOfficer" :disabled="selectedOfficer == null">Save</b-button>
			</div>
		</b-modal>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import Stepper from "../../components/Stepper.vue";
export default {
	name: "applications",
	components: {
		Stepper,
	},

	data: () => ({
		page: {
			title: "applications",
		},
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "agentName",
				label: "Agent Name",
				sortable: true,
			},
			{
				key: "customerName",
				label: "Customer Name",
				sortable: true,
			},
			{
				key: "loanAmount",
				label: "Loan Amount",
				sortable: true,
			},
			{
				key: "monthlyPayment",
				label: "Monthly Payment",
				sortable: true,
			},
			{
				key: "periodInMonths",
				label: "Months",
				sortable: true,
			},
			{
				key: "loanDescription",
				label: "Loan Type",
				sortable: true,
			},
			{
				key: "isActive",
				label: "Status",
				sortable: true,
			},
			{
				key: "progress",
				label: "Progress",
				sortable: false,
			},
			{
				key: "action",
				label: "Action",
				sortable: false,
			},
		],
		loading: false,
		filter: null,
		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		applicationSelected: null,
		show: false,
		officers: [],
		selectedOfficer: null,
		selected: null,
		formatter: new Intl.NumberFormat("en-US"),
	}),
	methods: {
		async getData(slug) {
			this.loading = true;
			await this.$store.dispatch("applications/get_allApplications", { skip: this.currentPage, take: this.perPage, slug });
			this.loading = false;
		},
		async goToView(item) {
			await this.$store.dispatch("productRequest/get_processResquest", item.id);
			this.applicationSelected = item;
			this.show = true;
		},
		goToEdit(item) {
			this.$router.push(`/loans/applications/${item.id}?customerId=${item.customerId}`);
		},
		checkProgress(data) {
			let count = 0;
			const items = ["main", "additionalDetails", "membercheck", "screening", "jobVerification", "approval", "signed", "transferredFunds"];
			items.forEach((element) => {
				if (element == "approval") {
					if (data.approvalLvl1 && data.approvalLvl1_2 && data.approvalLvl2) count += 12.5;
				}
				if (data[element]) count += 12.5;
			});
			return count;
		},
		generateCode(id) {
			let idStr = String(id);
			let zerosNeeded = Math.max(0, 7 - idStr.length);
			let code = "LA-" + "0".repeat(zerosNeeded) + idStr;

			return code;
		},
		async showOfficerModal(item) {
			this.selected = item;
			const response = await this.$store.dispatch("users/get_loanOfficers");
			this.officers = response.dataResult;
			this.$bvModal.show("modal-officer");
		},
		async setOfficer() {
			let data = {
				loanId: this.selected.id,
				agentId: this.selectedOfficer,
			};
			await this.$store.dispatch("applications/update_loanOfficer", data);
			await this.getData(this.$route.query.slug);
			this.$bvModal.hide("modal-officer");
		},
	},
	computed: {
		...mapGetters({
			applications: "applications/getApplications",
			totalApplications: "applications/getTotalApplications",
		}),
	},

	watch: {
		"$route.query.slug"(value) {
			this.getData(value);
		},
	},
	created() {
		this.getData(this.$route.query.slug);
	},
};
</script>
